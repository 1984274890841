import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";

const Social = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/social');
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Social Media</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Social Media</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Social Media</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">New Social Media</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post("/social", data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Social Link</label>
                                                            <input type="text" className="form-control" name="social_link" placeholder="Link" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Icon</label>
                                                            <input type="text" className="form-control" name="social_icon" placeholder='<i class="ci-facebook"></i>' required />
                                                            <div id="icon-help" class="form-text">Accept Only From
                                                                <button type="button" className="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#iconHelp">
                                                                    Icons List
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="iconHelp" tabIndex={-1} aria-labelledby="iconHelp" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Icons List</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <div className="modal-body">
                                                    <div className="text-center">
                                                        <div className="row">
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-add-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-add-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-add-document" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-add-document</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-add-location" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-add-location</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-add" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-add</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-add-user" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-add-user</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-announcement" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-announcement</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-down-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-down-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-down" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-down</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-left-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-left-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-left" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-left</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-right-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-right-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-right" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-right</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-up-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-up-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrow-up" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrow-up</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrows-horizontal" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrows-horizontal</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-arrows-vertical" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-arrows-vertical</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bag" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bag</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-basket" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-basket</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-behance" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-behance</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bell" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bell</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bluetooth" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bluetooth</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bluetooth-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bluetooth-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-battery" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-battery</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-book" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-book</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bookmark" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bookmark</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-briefcase" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-briefcase</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-calculator" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-calculator</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-camera" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-camera</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-card" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-card</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cart" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cart</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-chat" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-chat</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-check-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-check-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-check" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-check</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-clip" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-clip</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-close-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-close-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-close" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-close</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cloud-check" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cloud-check</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cloud-download" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cloud-download</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cloud-upload" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cloud-upload</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cloud" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cloud</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-coin" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-coin</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-coins" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-coins</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-compare" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-compare</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-currency-exchange" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-currency-exchange</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-delete-document" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-delete-document</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-delete-folder" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-delete-folder</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-delete-location" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-delete-location</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-delivery" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-delivery</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-diamond" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-diamond</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-discord" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-discord</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-document-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-document-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-document" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-document</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-dollar-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-dollar-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-dollar" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-dollar</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-download" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-download</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-dribbble" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-dribbble</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-earphones" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-earphones</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-edit-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-edit-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-edit" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-edit</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-euro-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-euro-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-euro" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-euro</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-exit-full-screen" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-exit-full-screen</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-eye" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-eye</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-eye-off" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-eye-off</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-facebook" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-facebook</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-filter-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-filter-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-filter" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-filter</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-flag" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-flag</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-folder" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-folder</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-forward" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-forward</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-full-screen" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-full-screen</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-gift" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-gift</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-globe" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-globe</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-google" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-google</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-hangouts" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-hangouts</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-heart-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-heart-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-heart" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-heart</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-help" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-help</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-home" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-home</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-idea" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-idea</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-image" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-image</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-increase" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-increase</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-instagram" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-instagram</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-joystick" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-joystick</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-key" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-key</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-lable" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-lable</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-laptop" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-laptop</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-lightning" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-lightning</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-link" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-link</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-linkedin" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-linkedin</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-list" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-list</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-loading" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-loading</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-location" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-location</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-locked" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-locked</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-loudspeaker" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-loudspeaker</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-mail" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-mail</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-map" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-map</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-menu-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-menu-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-menu" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-menu</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-message" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-message</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-messenger" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-messenger</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-mobile" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-mobile</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-money-bag" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-money-bag</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-money-box" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-money-box</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-monitor" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-monitor</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-moon" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-moon</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-music" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-music</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-navigation" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-navigation</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-odnoklassniki" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-odnoklassniki</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-package" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-package</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-percent" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-percent</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-phone" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-phone</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-pinterest" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-pinterest</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-plane" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-plane</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-play-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-play-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-play" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-play</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-printer" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-printer</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-reduce" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-reduce</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-reload" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-reload</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-remove-document" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-remove-document</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-remove-folder" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-remove-folder</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-reply" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-reply</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-rocket" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-rocket</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-ruler" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-ruler</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-rupee" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-rupee</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-scroll" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-scroll</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-search" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-search</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-security-announcement" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-security-announcement</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-security-check" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-security-check</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-security-close" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-security-close</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-security-prohibition" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-security-prohibition</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-send" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-send</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-server-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-server-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-server" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-server</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-settings" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-settings</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-share-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-share-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-share" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-share</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-sign-in" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-sign-in</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-sign-out" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-sign-out</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-skype" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-skype</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-smile" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-smile</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-speaker" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-speaker</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-star-filled" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-star-filled</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-star" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-star</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-star-half" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-star-half</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-sun" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-sun</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-support" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-support</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-target" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-target</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-telegram" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-telegram</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-thumb-up" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-thumb-up</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-thumb-up-filled" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-thumb-up-filled</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-tiktok" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-tiktok</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-time" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-time</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-trash" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-trash</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-tumblr" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-tumblr</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-turn-off" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-turn-off</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-twitter" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-twitter</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-unlocked" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-unlocked</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-upload" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-upload</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-user-circle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-user-circle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-user" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-user</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-viber" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-viber</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-video" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-video</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-view-grid" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-view-grid</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-view-list" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-view-list</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-vimeo" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-vimeo</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-vk" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-vk</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-wallet" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-wallet</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-watch" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-watch</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-weather" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-weather</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-wechat" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-wechat</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-whatsapp" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-whatsapp</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-youtube-outline" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-youtube-outline</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-youtube" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-youtube</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-caret" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-caret</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-dot-square" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-dot-square</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-paypal" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-paypal</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-wash" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-wash</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bleach" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bleach</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-hand-wash" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-hand-wash</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-ironing" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-ironing</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-sound-waves" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-sound-waves</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-paprika" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-paprika</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-chili" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-chili</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-canned-food" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-canned-food</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-toothbrush" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-toothbrush</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-pumpkin" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-pumpkin</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-orange" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-orange</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-broccoli" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-broccoli</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-sauce" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-sauce</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-soap" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-soap</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-toilet-paper" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-toilet-paper</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-lemon" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-lemon</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-bread" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-bread</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-jam" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-jam</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-fish" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-fish</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-wine" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-wine</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-truck" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-truck</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-tag" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-tag</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-eggs" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-eggs</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-store" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-store</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-ham-leg" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-ham-leg</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-discount" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-discount</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-open" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-open</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-juice" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-juice</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cheese" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cheese</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-milk" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-milk</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-cashier" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-cashier</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-avocado" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-avocado</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-apple" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-apple</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-pizza" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-pizza</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-scale" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-scale</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-ice-cream" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-ice-cream</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-water-bottle" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-water-bottle</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-carrot" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-carrot</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-basket-alt" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-basket-alt</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-corn" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-corn</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-frozen" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-frozen</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-lollipop" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-lollipop</div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-4 col-6 mb-4"><i className="h2 text-body ci-pot" />
                                                                <div className="border-top border-bottom mt-1 py-1 fs-sm text-muted">ci-pot</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Icon</th>
                                        <th>Url</th>
                                        <th>Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td><div className="x" dangerouslySetInnerHTML={{ __html: item.social_icon }}></div> </td>
                                                <th><a href={item.social_link} target="_blank">{item.social_link}</a></th>
                                                <td>
                                                    <span className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </span>
                                                    <span type="button" className="badge bg-danger mx-1"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Are you sure?',
                                                                text: "You won't be able to revert this!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: "Yes, delete it!",
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/social/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                        <icon.Trash size="18" />
                                                    </span>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/social/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('updated successfully');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Social Link</label>
                                                                        <input type="text" className="form-control" name="social_link" placeholder="Social Link" defaultValue={item.social_link} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Icon</label>
                                                                        <input type="text" className="form-control" name="social_icon" placeholder='<i class="fab fa-facebook"></i>' defaultValue={item.social_icon} required />
                                                                        <div id="icon-help" class="form-text">Accept Only From
                                                                            <button type="button" className="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#iconHelp">
                                                                                Icons List
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                    <button type="submit" className="btn btn-primary">update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Social;