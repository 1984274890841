import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

const Sidebar = ({ icon }) => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    Doski<span>Trade</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">Main</li>
                    <li className="nav-item">
                        <Link to="/products" className="nav-link">
                            <icon.Layers className="link-icon" />
                            <span className="link-title">Products</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/categories" className="nav-link">
                            <icon.Tag className="link-icon" />
                            <span className="link-title">Categories</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/collections" className="nav-link">
                            <icon.GitBranch className="link-icon" />
                            <span className="link-title">Collections</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/brands" className="nav-link">
                            <icon.Flag className="link-icon" />
                            <span className="link-title">Brands</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/slides" className="nav-link">
                            <icon.Image className="link-icon" />
                            <span className="link-title">Slides</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/ads" className="nav-link">
                            <icon.Radio className="link-icon" />
                            <span className="link-title">Ads</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/banners" className="nav-link">
                            <icon.Gift className="link-icon" />
                            <span className="link-title">Banner</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">General</li>
                    <li className="nav-item">
                        <Link to="/users" className="nav-link">
                            <icon.Users className="link-icon" />
                            <span className="link-title">Users</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/socials" className="nav-link">
                            <icon.Link2 className="link-icon" />
                            <span className="link-title">Social Link</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/settings" className="nav-link">
                            <icon.Archive className="link-icon" />
                            <span className="link-title">Settings</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav >
    );
}

export default Sidebar;