import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";

const Settings = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/setting');
        setData(data);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);


    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Site Information</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Site Information</h6>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Logo</th>
                                        <th>Name</th>
                                        <th>Phone No.</th>
                                        <th>Whatsapp No.</th>
                                        <th>E-Mail</th>
                                        <th>Address (English)</th>
                                        <th>Address (Arabic)</th>
                                        <th>Description (English)</th>
                                        <th>Description (Arabic)</th>
                                        <th>Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.site_logo && <img src={`../../${item.site_logo}`} alt={`image-${index}`} className="img-fluid" />}</td>
                                                <td>{item.name}</td>
                                                <td>{item.phone_no}</td>
                                                <td>{item.whatsapp}</td>
                                                <td>{item.email}</td>
                                                <td>{item.address_en}</td>
                                                <td>{item.address_ar}</td>
                                                <td>{item.description_en}</td>
                                                <td>{item.description_ar}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/setting/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('updated successfully');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Site Name</label>
                                                                        <input type="text" className="form-control" name="site_name" placeholder="Name" defaultValue={item.site_name} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Phone No.</label>
                                                                        <input type="text" className="form-control" name="phone_no" placeholder="Phone Number" defaultValue={item.phone_no} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Whatsapp No.</label>
                                                                        <input type="text" className="form-control" name="whatsapp" placeholder="Whatsapp Number" defaultValue={item.whatsapp} required />
                                                                        <div className="text-muted">* Please add country code before the number. Example: "+964" for Iraq</div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">E-Mail</label>
                                                                        <input type="text" className="form-control" name="email" placeholder="E-Mail" defaultValue={item.email} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Address (English)</label>
                                                                        <input type="text" className="form-control" name="address_en" placeholder="Address" defaultValue={item.address_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Address (Arabic)</label>
                                                                        <input type="text" className="form-control" name="address_ar" placeholder="Address" defaultValue={item.address_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description (English)</label>
                                                                        <textarea name="description_en" className="form-control" defaultValue={item.description_en} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description (Arabic)</label>
                                                                        <textarea name="description_ar" className="form-control" defaultValue={item.description_ar} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Site Logo</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="site_logo" defaultValue={selectedFile ? selectedFile : item.site_logo} required />
                                                                        <img className="img-fluid rounded mt-2" src={`../../${item.site_logo}`} alt={item.site_name} />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                    <button type="submit" className="btn btn-primary">update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Settings;