import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from '../Pages/Login';
import Products from '../Pages/Products';
import Users from '../Pages/Users';
import Categories from '../Pages/Categories';
import Dashboard from '../Pages/Dashboard';
import Social from '../Pages/Social';
import Slides from '../Pages/Slides';
import Ads from '../Pages/Ads';
import Settings from '../Pages/Settings';
import Brands from '../Pages/Brands';
import Collections from '../Pages/Collections';
import Banners from '../Pages/Banners';
import CollectionItems from '../Pages/CollectionItems';


const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
                .0
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Products icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/users",
        element: <APP><Users icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/socials",
        element: <APP><Social icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/slides",
        element: <APP><Slides icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/ads",
        element: <APP><Ads icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/banners",
        element: <APP><Banners icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/settings",
        element: <APP><Settings icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/categories",
        element: <APP><Categories icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/brands",
        element: <APP><Brands icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collections",
        element: <APP><Collections icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collection-items/:id",
        element: <APP><CollectionItems icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/products",
        element: <APP><Products icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
]);

export default Routes;